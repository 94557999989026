import { ApiHelper } from '../Helpers/ApiHelper';
import { Tasks as TasksModel, Task } from '../Models/';

export default class Tasks extends ApiHelper {

    constructor(environment, program, xApiKey, callbackTokenUpdated) {
        super(environment, program, xApiKey, callbackTokenUpdated);
    }

    /**
     * @returns {Object} Tasks Object
     */
    getOpenTasks() {
        return new Promise((resolve, reject) => {
            super.get('tasks/open', {
            }, function (data) {
                resolve(new TasksModel(data));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * @returns {Object} Tasks Object
     */
    getClosedTasks() {
        return new Promise((resolve, reject) => {
            super.get('tasks/closed', {
            }, function (data) {
                resolve(new TasksModel(data));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * Cancels a specific task by id
     * @param {String} taskId 
     */
    cancelTask(taskId) {
        return new Promise((resolve, reject) => {
            super.post('tasks/'.concat(taskId).concat('/cancel'), {
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
    * Reopens a specific task by id
    * @param {String} taskId 
    */
    reopenTask(taskId) {
        return new Promise((resolve, reject) => {
            super.post('tasks/'.concat(taskId).concat('/reopen'), {
            }, function (data) {
                resolve(data);
            }, function (data) {
                if (data) {
                    reject(data);
                } else if (data.message) {
                    reject(data.message);
                } else {
                    reject('unknown failure');
                }
            });
        });
    }

    /**
    * Deletes a specific task by id
    * @param {String} taskId 
    */
    deleteTask(taskId) {
        return new Promise((resolve, reject) => {
            super.post('tasks/'.concat(taskId).concat('/delete'), {
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * 
     * @param {String} taskId 
     * @returns {} empty response
     */

    markTaskAsRead(taskId){
        return new Promise((resolve, reject) => {
            super.post('tasks/'.concat(taskId).concat('/read'), {
            }, function (data) {
                resolve();
            }, function (data) {
                reject();
            });
        });
    }



    /**
    * Returns a specific task by id
    * @param {String} taskId 
    * @returns {Object} task object
    */
    getTask(taskId) {
        return new Promise((resolve, reject) => {
            super.get('tasks/'.concat(taskId), {
            }, function (data) {
                resolve(new Task(data.task));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * @param {Object[]} messages - array of message objects
     * @param {String} messages[].type - type of message eg. text
     * @param {String} messages[].text - the message text
     * @param {String} title title of the task
     * @param {String} offset
     * @param {Boolean} createDefaultMessage
     * @param {String} limit
     * @returns {Promise} Promise
     */
    createTask(title, note, createDefaultMessage, offset, limit, messages) {
        return new Promise((resolve, reject) => {
            super.post('tasks/create', {
                "messages": messages,
                "title": title,
                "notes": note,
                "offset": offset,
                "create_default_message": createDefaultMessage,
                "limit": limit
            }, function (data) {
                resolve(new Task(data.task));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
    * @param {Object} details object containing message parameters
    * @param {String} details.type type of message eg. text
    * @param {String} details.text the message text
    * @param {String} details.taskId the id of the task the message is intended for
    * @param {String} details.attachmentId
    */
    message(details) {
        return new Promise((resolve, reject) => {
            if (details) {
                super.post('tasks/'.concat(details.taskId).concat('/message'), {
                    type: details.type,
                    text: details.text,
                    attachment_id: details.attachmentId,
                }, function (data) {
                    resolve(data);
                }, function (data) {
                    reject(data);
                });
            } else {
                reject('Invalid details object');
            }
        });
    }

    rate(message, unixTimeStamp, rating, taskId) {
        return new Promise((resolve, reject) => {
            super.post('tasks/'.concat(taskId).concat('/rate'), {
                "message": message,
                "unix_timestamp": unixTimeStamp,
                "rating": rating
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    rating(unixTimeStamp, taskId) {
        return new Promise((resolve, reject) => {
            super.post('tasks/'.concat(taskId).concat('/rating'), {
                "unix_timestamp": unixTimeStamp,
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }
}