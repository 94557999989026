import React from 'react';
import './MModalButton.scss';
import MModal from '../MModal';

class MModalButton extends React.PureComponent{

	constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <div>
    
        <a onClick={this.toggleModal} className="MModal_Button_Hey">{this.props.title}</a>

        <MModal show={this.state.isOpen} onClose={this.toggleModal}>
          {this.props.children}
        </MModal>
        
        
      </div>
    );
  }

}

export default MModalButton;