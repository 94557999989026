import React from 'react';
import MessageModel from '../../../Model/MessageModel'
import ConnectionType from '../../../Connect/ConnectionType';
import Button from '../../Button';

import MessageItemContainer from './Message/MessageItemContainer';
import './TaskDetails.scss';

import theme from '../../../Theme/theme.module.scss';
import moment from 'moment';
import TaskListContainer from './TaskListContainer';
import TaskModel from '../../../Model/TaskModel';
import TaskControl from '../../../Control/TaskControl';

const logo = require('../../../Assets/Img/appIcon.png')

class TaskDetails extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.state = {
      taskType : TaskListContainer.TYPE_OPEN
    }

    this.list = React.createRef();

    this.data = MessageModel.getInstance().getData();
    this.onNewData = this.onNewData.bind(this);
    this.onNewSocketData = this.onNewSocketData.bind(this);
    this.renderList = this.renderList.bind(this);
    this.createDataKey = this.createDataKey.bind(this);
    this.createSpacerKey = this.createSpacerKey.bind(this);
    this.onClearData = this.onClearData.bind(this);
    this.renderHead = this.renderHead.bind(this);
    this.hideDetails = this.hideDetails.bind(this);
    this.onCancelPressed = this.onCancelPressed.bind(this);
    this.onReopenPressed = this.onReopenPressed.bind(this);
    this.onDeletePressed = this.onDeletePressed.bind(this);
  }

  componentDidMount(){
    MessageModel.getInstance().addConnection(ConnectionType.TYPE_MESSAGE_DATA, this.onNewData)
    MessageModel.getInstance().addConnection(ConnectionType.TYPE_SOCKET_MESSAGE_DATA, this.onNewSocketData)
    MessageModel.getInstance().addConnection(ConnectionType.TYPE_MESSAGE_CLEAR, this.onClearData)
    this.scrollToBottom();
  }

  componentDidUpdate(){
    this.scrollToBottom();
  }

  componentWillMount(){
    MessageModel.getInstance().removeConnection(ConnectionType.TYPE_MESSAGE_DATA, this.onNewData)
    MessageModel.getInstance().removeConnection(ConnectionType.TYPE_SOCKET_MESSAGE_DATA, this.onNewSocketData)
    MessageModel.getInstance().removeConnection(ConnectionType.TYPE_MESSAGE_CLEAR, this.onClearData)
  }

  onClearData(){
    this.onNewData({data:{}})
  }

  scrollToBottom(){
    if(this.list === undefined){
      return;
    }

    this.list.current.scrollTop = this.list.current.scrollHeight
  }

  onNewSocketData(data){
    if(this.taskId !== data.id){
      return;
    }
    TaskControl.getInstance().markTaskAsRead(this.taskId)
    this.onNewData(data);

  }

  onCancelPressed(){
    TaskControl.getInstance().cancelTask(this.taskId);
    this.hideDetails();
  }

  onReopenPressed(){
    TaskControl.getInstance().reopenTask(this.taskId);
    this.hideDetails();
  }

  onDeletePressed(){
    TaskControl.getInstance().deleteTask(this.taskId);
    this.hideDetails();
  }

  onNewData(data){
    this.taskId = data.id
    this.data = data.data;
    this.forceUpdate();
  }

  createDataKey(id){
    let key = id + "";
    return key
  }

  createSpacerKey(id){
    return id + "_spacer";
  }

  hideDetails(){
    MessageModel.getInstance().dispatch(ConnectionType.TYPE_TASK_SELECTED, {showChat: false});
  }

  renderHeadButtons(){
    if(this.data.open){
      return(
        <React.Fragment>
          <Button
            onClick={this.onCancelPressed}
            theme={this.props.theme}
            empty
            text="Cancel Task"
            className="uppercase fz-15 h-40  show-lg">
          </Button>

          <Button
            onClick={this.onCancelPressed}
            theme={this.props.theme}
            empty
            textonly
            text="Cancel Task"
            className="fz-13 bold p-5 hide-lg">
          </Button>
        </React.Fragment>
      )
    }
    else{
      return(
        <React.Fragment>
          <Button
            onClick={this.onReopenPressed}
            theme={this.props.theme}
            empty
            text="Reopen Task"
            className="uppercase fz-15 h-40 show-lg">
          </Button>
          <Button
            onClick={this.onDeletePressed}
            theme={this.props.theme}
            empty
            text="Delete Task"
            className="uppercase fz-15 h-40 mt-15 show-lg">
          </Button>

          <Button
            onClick={this.onReopenPressed}
            theme={this.props.theme}
            empty
            textonly
            text="Reopen Task"
            className="fz-13 bold p-5 hide-lg">
          </Button>
          <Button
            onClick={this.onDeletePressed}
            theme={this.props.theme}
            empty
            textonly
            text="Delete Task"
            className="fz-13 bold p-5 mt-10 hide-lg">
          </Button>
        </React.Fragment>
      )
    }
  }


  renderHead(){
    if(this.data.title){
      let date = moment(this.data.createdAt).format('DD MMM YYYY');

      return (
        <div className={`${theme[this.props.theme]} taskDetailsHead`}>
          <Button onClick={this.hideDetails} theme={this.props.theme} textonly className="icon-left mr-15 fz-17 h-40 hide-lg" ></Button>
          <div className="wrapper wrapper_flex-column-center taskDetailsHeadText pr-5 hide-lg">
            <h2 className="fz-13 bold">{this.data.title}</h2>
            <p className="fz-10 mt-5">{date}</p>
          </div>
          <div className="wrapper wrapper_flex-column-end flex-1 hide-lg">
            {this.renderHeadButtons()}
          </div>

          <h2 className="taskTitle show-lg taskDetailsHeadText">{this.data.title}</h2>
          <div className="wrapper wrapper_flex-column-end flex-1 show-lg">
            {this.renderHeadButtons()}
          </div>

        </div>
      )
    }
  }

  renderList(){
    if(this.data === undefined || this.data.messages === undefined || this.data.messages.length === 0){
      return (
        <div className='TaskEmptyImage'>
          <img src={logo} alt="HeyJude Logo" />
        </div>
      )
    }

    var dataCollection = [];
    var previousSender;
    var previousDataSet;
    var previousTime = 0;

    for (var dataItem of this.data.messages){
      dataItem.taskId = this.taskId;
      var currentDataSet = []
      let currentTime = dataItem.unixTimeStamp;
      let splitByTime = true//(currentTime - previousTime) > 300

      if(dataCollection.length === 0){
        currentDataSet.push(dataItem);
        dataCollection.push(currentDataSet);
      }else{
        currentDataSet.push(dataItem);
        dataCollection.push(currentDataSet);
      }

      previousSender = dataItem.fromUser;
      previousDataSet = currentDataSet; 
      previousTime = dataItem.unixTimeStamp
    }

    let limit = this.data.messages.length;
    var count = 0;
    var displayList = [];

    for (var dataSet of dataCollection){
      displayList.push(
        <MessageItemContainer theme={this.props.theme} key={this.createDataKey(count+"_set")} data={dataSet}/>
      )
      count++
    }

    return displayList;
  }

  render(){
    return(
      <React.Fragment>

        {this.renderHead()}

        <div className="TaskDetailsContainer" ref={this.list}>
          {this.renderList()}
        </div>
      </React.Fragment>
    )
  }

}

export default TaskDetails;