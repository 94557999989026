import React from 'react';
import './MMenu.scss';
import MModalButton from '../MModalButton';

class MMenu extends React.PureComponent{

	constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="MMenu_Hey">
      <MModalButton title="How does this work?">
      	<h1 className="Hey_Title">Hey Ello is your go-to assistant that’s got you!</h1>
				<p className="Hey_Copy">Whatever you need help with, we’re here for you, day and night, 24/7/365. You holla – we hustle! Easy.</p>
				<p className="Hey_Copy"><strong>Step 1.</strong> Give us something to do by 'creating a new task'.</p>
				<p className="Hey_Copy"><strong>Step 2.</strong> Describe what you need by giving the task a name. Make it something easy to remember so you can track all the different tasks you have given us.</p>
				<p className="Hey_Copy"><strong>Step 3.</strong> Now the hustle begins. We might have a few questions, or you may need to provide more detail around the task. You can use the chat feature to do that.</p>
      </MModalButton>
      <MModalButton title="Eating out?">
      	<h1 className="Hey_Title">Eating out? Want some cash back?</h1>
      	<p className="Hey_Copy">You can get <strong>up to R120 off your second most expensive meal</strong> when you spend R200 or more - seven days a week - at over 1 000 participating Crave 2-4-1 Dining restaurants nationwide.</p>
				<p className="Hey_Copy">Just let us know where you are and what you feel like eating. We'll arrange a booking and let you know how to claim your 2-4-1 Crave Dining benefit.</p>
			</MModalButton>
      <MModalButton title="Movie night?">
      	<h1 className="Hey_Title">Heading to the big screen?</h1>
      	<p className="Hey_Copy">Make it a movie night. Get up to 50% off movie tickets and Coke and popcorn combos at participating cinemas.</p>
      	<p className="Hey_Copy">4x2D and 4x3D deals every month.</p>
      	<p className="Hey_Copy">You get: <strong>8x Coke and popcorn deals every month.</strong></p>
      </MModalButton>
      <MModalButton title="Spa day?">
				<h1 className="Hey_Title">Feel like spoiling yourself with a treatment?</h1>
				<p className="Hey_Copy">The hustle can get hectic – we feel you. But we can help you can get the chill time you deserve.</p>
				<p className="Hey_Copy">Let us pick the spa and make the booking for you, and we'll be able to give you some great specials and extra treatments.</p>
      </MModalButton>
      <MModalButton title="Need tickets?">
				<h1 className="Hey_Title">Do you want to go to that event or that show?</h1>
				<p className="Hey_Copy">Whether it’s the hottest concerts, sports events, comedy jams, or festivals, you get the best deals on where it’s going down.</p>
				<p className="Hey_Copy">Simply <strong>create a new task</strong> and tell us where you want to go. We'll source the tickets for you.</p>
      </MModalButton>
      </div>
    );
  }

}

export default MMenu;
