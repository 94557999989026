//ConnectionType.MEDIA_FOR_UPLOAD_UPDATED
import React from 'react';
import './MessageMediaUploadList.scss';

import MediaModel from '../../../../Model/MediaModel';
import ConnectionType from '../../../../Connect/ConnectionType';
import MediaControl from '../../../../Control/MediaControl';
import Button from '../../../Button';

import theme from '../../../../Theme/theme.module.scss';



const documentIcon = require('../../../../Assets/Img/messageIconPDF.png')
const recordIcon = require('../../../../Assets/Img/iconRecord.png')


class MessageMediaUploadList extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.state={
      data: MediaModel.getInstance().getData().files
    }

    this.onClearAttachmentsPressed = this.onClearAttachmentsPressed.bind(this);
    this.onAttachmentListUpdated = this.onAttachmentListUpdated.bind(this);
  }

  componentDidMount(){
    MediaModel.getInstance().addConnection(ConnectionType.TYPE_MEDIA_FOR_UPLOAD_UPDATED,this.onAttachmentListUpdated);
  }

  componentWillUnmount(){
    MediaModel.getInstance().removeConnection(ConnectionType.TYPE_MEDIA_FOR_UPLOAD_UPDATED,this.onAttachmentListUpdated);
  }

  onClearAttachmentsPressed(){
    MediaControl.getInstance().clear();
  }

  onAttachmentListUpdated(data){
    this.setState({
      data:data.files,
    })

    this.forceUpdate();
  }

  renderLayout(){
    var displayList = [];
    
    let data = this.state.data
    let keys = Object.keys(data)

    for (var key of keys){
      let fileData = data[key]

      if(fileData.type.includes("pdf")){
        displayList.push(
          <div key={fileData.id} className={`${theme.default} MediaAttachmentListItem`}>
            <img src={documentIcon} alt="Attachment"/>
          </div>
        )
      }else if(fileData.type.includes("image")){
        displayList.push(
          <div key={fileData.id} className={`${theme.default} MediaAttachmentListItem`}>
            <img src={fileData.url} alt="Attachment"/>
          </div>
        )
      }else if(fileData.type.includes("audio")){
        displayList.push(
          <div key={fileData.id} className={`${theme.default} MediaAttachmentListItem`}>
            <img src={recordIcon} alt="Attachment"/>
          </div>
        )
      }
    }

    return displayList;
  }

  render(){
    if(Object.keys(this.state.data).length === 0){
      return null;
    }

    return(
      <div className={`${theme.default} MediaAttachmentList`}>
        <div className="MediaAttachmentContainer">
          {this.renderLayout()}
        </div>
        <Button
          onClick={this.onClearAttachmentsPressed}
          theme={this.props.theme}
          textonly
          className="fz-15 p-5 icon-cancel w-100 mt-10 d-b"
        ></Button>
      </div>
    )
  }

}

export default MessageMediaUploadList;
