import React from 'react';
import SocketModel from '../../../Model/SocketModel';
import UserModel from '../../../Model/UserModel';
import UserControl from '../../../Control/UserControl';
import ConnectionType from '../../../Connect/ConnectionType';
import MessageModel from '../../../Model/MessageModel';

import Picture from '../../Picture/index';
import style from './titleBar.module.scss';
import theme from '../../../Theme/theme.module.scss';
import Button from '../../Button/index';
const logo = require('../../../Assets/Img/appIcon.png');

class TitleBar extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.state = {
      socketStatus: SocketModel.getInstance().getData().socketStatus,
      currentTaskTitle : ''
    }

    this.userData = UserModel.getInstance().getData();

    this.onSocketStatus = this.onSocketStatus.bind(this);
    this.onMessageData = this.onMessageData.bind(this);
    this.renderUserDetails = this.renderUserDetails.bind(this);
    this.renderSocketStatus = this.renderSocketStatus.bind(this);
    this.onNameClick = this.onNameClick.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  componentDidMount(){
    SocketModel.getInstance().addConnection(ConnectionType.TYPE_SOCKET_STATUS_UPDATE, this.onSocketStatus)
    MessageModel.getInstance().addConnection(ConnectionType.TYPE_MESSAGE_DATA, this.onMessageData)
  }

  componentWillUnmount(){
    SocketModel.getInstance().removeConnection(ConnectionType.TYPE_SOCKET_STATUS_UPDATE, this.onSocketStatus)
    MessageModel.getInstance().removeConnection(ConnectionType.TYPE_MESSAGE_DATA, this.onMessageData)
  }

  onSocketStatus(status){
    this.setState({socketStatus:status})
  }

  onMessageData(data){
    this.setState({
      currentTaskTitle:data.data.title
    });
  }

  onNameClick(){
    UserControl.getInstance().toggleUserProfile()
  }

  signOut(){
    UserControl.getInstance().signOut();
    window.history.pushState({}, document.title, "/");
  }

  renderUserDetails(){
    return(
      <button onClick={this.onNameClick}>
        {this.renderSocketStatus()}
        {this.userData.firstName} {this.userData.lastName}

        </button>
    )
  }

  renderSocketStatus(){
    return(
      <div className={
        this.state.socketStatus === SocketModel.SOCKET_CONNECTED
          ? 'SocketConnected'
          : 'SocketDisconnected'
      }/>
    )
  }

  render(){
    return(
      <div key="TitleBarInner" className={`${style.titleBar} ${theme[this.props.theme]}`}>
        <div className="wrapper wrapper_left-half">
          <Picture src={logo} logo alt="Chat Icon"></Picture>
          <div className="text fz-15 ml-15"><img src={require('../../../Assets/Img/logoTagline.svg')} /></div>
        </div>
        <div className="wrapper wrapper_right-half hide-xl">
          <Button onClick={this.onNameClick}
              textonly
              theme={this.props.theme}
              content={
                <div className="hamburger">
                  <span className={`${theme[this.props.theme]} hamburger__item`}></span>
                </div>
              }
          ></Button>
        </div>
        <div className="wrapper wrapper_right-half show-xl">
          <Button
              onClick={this.onNameClick}
              textonly
              theme={this.props.theme}
              className="h-40"
              content={
                <Picture src={this.userData.profileImage} logo alt="Avatar"></Picture>
              }
          ></Button>
          <Button
              textonly
              onClick={this.signOut}
              className="icon-logout color-white fz-20 ml-15"
          ></Button>
        </div>
        {/* {this.renderUserDetails()} */}
      </div>
    )
  }
}

export default TitleBar;
