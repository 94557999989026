import React from 'react';
import './PaymentOverlay.scss';
import theme from '../../Theme/theme.module.scss';
import style from "../../Screens/ForgotPassword/forgotPassword.module.scss";
import UserControl from "../../Control/UserControl";
import AlertLoader from "./AlertLoader";
import * as moment from "moment";
import Button from "../Button";

const defaultState = {
  show:false,
  title:'',
  message:'',
  acceptText:'Accept',
  acceptCallBack:undefined,
  cancelText:undefined,
  cancelCallBack:undefined,
  paymentStatus:null,
  paymentMethods:null,
  paymentMethodsSelect:null,
  paymentRequestId: null,
  paymentStatusId: null,
  paymentLinkUrl: "",
  paymentRequestDetails: "",
  showPaymentLink: false,
  hideAddPaymentMethodButton: false,
  taskId: null
};

class PaymentOverlay extends React.PureComponent{

    static defaultProps = {
        theme: 'default',
    };

    static setInstance(instance){
      PaymentOverlay.instance = instance;
    } 

    static getInstance(instance){
      return PaymentOverlay.instance;
    }

    constructor(){
      super();
      this.state = defaultState;
      this.singleButtonMode = (defaultState.cancelText==="");
      this.onAcceptClick = this.onAcceptClick.bind(this);
      this.onCancelClick = this.onCancelClick.bind(this);
      this.onSubmitClick = this.onSubmitClick.bind(this);
      this.showPaymentLink = this.showPaymentLink.bind(this);
      this.closeShowPaymentLink = this.closeShowPaymentLink.bind(this);
      this.hide = this.hide.bind(this);
      this.show = this.show.bind(this);
      this.getButtonMode = this.getButtonMode.bind(this);
      this.renderCancelButton = this.renderCancelButton.bind(this);
      this.selectPaymentMethod = React.createRef();

      PaymentOverlay.setInstance(this);
    }

    onAcceptClick(e){
      this.hide();

      if(this.state.acceptCallBack !== undefined){
        this.state.acceptCallBack();
      }
    }

    onCancelClick(e){
      this.hide();

      if(this.state.cancelCallBack !== undefined){
        this.state.cancelCallBack();
      }
    }

    async onSubmitClick(){
        let paymentStatus = document.getElementById('paymentStatus_' + this.state.paymentRequestId);
        let payNowButton = document.getElementById('payNowButton_' + this.state.paymentRequestId);
        let selectedPaymentMethod = this.selectPaymentMethod.current.options[this.selectPaymentMethod.current.selectedIndex].value;
        let data = UserControl.getInstance().makePayment(this.state.paymentRequestId, selectedPaymentMethod);
        AlertLoader.getInstance().show({show:true,message:'Processing...'});
        await data.then(response => {
            if(response.result == true){
                this.hide();
                paymentStatus.innerHTML = 'paid';
                payNowButton.className += "pay-now-hide";
                AlertLoader.getInstance().show({show:true,message:'Payment made successfully!'});
                setTimeout(()=>{
                    AlertLoader.getInstance().show({show:false,message:''});
                },3000)
            } else {
                AlertLoader.getInstance().show({show:true,message:'Payment failed, please try again.'});
                setTimeout(()=>{
                    AlertLoader.getInstance().show({show:false,message:''});
                },3000);
            }
        });
    }

    async show(options){
      this.singleButtonMode = (options.cancelText=== undefined);

      this.setState ({
        ...defaultState,
        ...options
      });
      if(this.state.paymentMethods.length <= 0){
          let paymentLinkUrl = await this.paymentLinkUrl();
          this.setState({paymentLinkUrl:paymentLinkUrl});
      }
    }

    hide(){
      this.setState({...defaultState})
    }

    renderCancelButton(){
      if(this.state.cancelText === undefined){
        return null;
      }
      return (
        <span><button className="ContentOverlayCancel" type="button" onClick={this.onCancelClick}>{this.state.cancelText}</button></span>
      )
    }

    getButtonMode(){
      if(this.singleButtonMode){
        return "ContentOverlayButtonAcceptSingle"
      }else{
        return "ContentOverlayButtonAccept"
      }
    }

    renderSubmitButton(){

        if(this.state.cancelText === undefined){
            return null;
        }
        return (
            <span><button className="ContentOverlayCancel" type="button" onClick={this.onSubmitClick}>Pay</button></span>
        )
    }

    async paymentLinkUrl(){
        let customParams = {
            'provider': 'peach',
            'custom_param1': this.state.taskId.toString(),
            'custom_param2': this.state.paymentRequestId.toString()
        };
        let getPaymentLink = UserControl.getInstance().getPaymentLink(customParams);
        return await getPaymentLink.then(response => {
            return response.data
        });
    }

    renderIframe(){
        return <div>
            <iframe id="payment_link" align={'center'} scrolling={'yes'} width="1000" height="700"  src={this.state.paymentLinkUrl}></iframe>
        </div>
    }

    async showPaymentLink(){
        this.setState({showPaymentLink:true});
        this.setState({hideAddPaymentMethodButton: true})
    }

    async closeShowPaymentLink(){
        this.setState({showPaymentLink:false});
    }

    render() {
        let description = this.state.paymentRequestDetails.description ? this.state.paymentRequestDetails.description : '';
        let currency = this.state.paymentRequestDetails.currency ? this.state.paymentRequestDetails.currency : '';
        let amount = this.state.paymentRequestDetails.amount ? this.state.paymentRequestDetails.amount : '';
        let transactionId = this.state.paymentRequestDetails.id ? this.state.paymentRequestDetails.id : '';
        let date = this.state.paymentRequestDetails.unixTimeStamp ? moment.unix(this.state.paymentRequestDetails.unixTimeStamp).format("DD MMMM YYYY") : '';

        if (!this.state.show) {
            return null;
        }

        if(this.state.paymentMethods.length > 0){
            return (
                <div className={`${theme[this.props.theme]} ContentOverlayPage`}>
                    <div className={`${theme[this.props.theme]} ContentOverlayContainer`}>
                        <div className="ContentOverlayButtonContainer">
                            <h3 className={'AlertLoaderText mt-1-rem mb-1-rem'}>{description}</h3>
                            <h2 className={'AlertLoaderText mt-1-rem mb-1-rem'}>{currency} {amount}</h2>
                            <p className={'AlertLoaderText mt-1-rem mb-1-rem'}>Transaction ID: {transactionId}</p>
                            <p className={'AlertLoaderText mt-1-rem mb-1-rem'}>Date: {date}</p>
                            <p className={'AlertLoaderText mt-1-rem mb-1-rem'}>Select a payment method below :</p>
                            <select className={`${style.FPSelect} mb-2-rem`}
                                    ref={this.selectPaymentMethod}>
                                {this.state.paymentMethods.map(value =>
                                    <option key={value.id} selected={value.default === true}
                                    value={value.id}>{value.default === true ? 'Default' : ''} Card
                                    : **** **** **** {value.last_four_digits}
                                    </option>)
                                }
                            </select>
                            <div>
                                {this.renderSubmitButton()}
                                {this.renderCancelButton()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${theme[this.props.theme]} ContentOverlayPage`}>
                    <div className={`${theme[this.props.theme]} ContentOverlayContainer`}>
                        <div className="ContentOverlayButtonContainer">
                            {this.state.hideAddPaymentMethodButton === false ?
                                <p className={'AlertLoaderText mt-1-rem mb-1-rem mr-1-rem ml-1-rem'}>
                                    You do not have any payment methods linked. Please click the button below to link a payment method :
                                </p> : ''
                            }
                            {this.state.hideAddPaymentMethodButton === false ?
                                <Button onClick={this.showPaymentLink} text={'Add a Payment Method'} className={'pay-now mt-1-rem'}></Button> :
                                ''
                            }
                            { this.state.showPaymentLink ?
                                this.renderIframe() :
                                ""
                            }
                            <Button onClick={this.hide} text={'Cancel'} className={'pay-now-cancel mt-1-rem'}></Button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default PaymentOverlay;