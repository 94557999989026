import React from 'react';
import PropTypes from 'prop-types';
import './MModal.scss';

class MModal extends React.PureComponent {
  
  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    return (
      <div className="backdropStyle">
        <div className="modalStyle">
          
          {this.props.children}

          <div>
            <button onClick={this.props.onClose} className="closeButton">
              X
            </button>
          </div>
          
        </div>
      </div>
    );
  }
}

MModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default MModal;