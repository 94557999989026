import React from 'react';
import './UserProfile.scss';
import UserModel from '../../../Model/UserModel';
import UserControl from '../../../Control/UserControl';
import ConnectionType from '../../../Connect/ConnectionType';
import Picture from '../../Picture';
import Button from '../../Button';

import theme from '../../../Theme/theme.module.scss';

import InputText from '../../InputText';

import MMenu from '../../MMenu';
import MModalButton from "../../MModalButton";

class UserProfile extends React.PureComponent {

  static defaultProps = {
    theme: 'default'
  };

    constructor(props) {
        super(props);
        this.userData = UserModel.getInstance().getData();
        this.state = {
            show: false,
            showEdit:false,
            showCardDetails:false,
            transition:'hide',
            noCover: true,
            iframeUrl: '',
            cardDetailsForm: true,
            threeDSecureUrl: "",
            userImage: null,
            userImageType: '',
            userImageValid: true,
            userImageFile: null
        };

        this.onToggleProfile = this.onToggleProfile.bind(this);
        this.onSignOutPressed = this.onSignOutPressed.bind(this);
        this.renderEdit = this.renderEdit.bind(this);
        this.renderCardDetails = this.renderCardDetails.bind(this);
        this.selectImage = this.selectImage.bind(this);
        this.currentImage = this.currentImage.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
    }

    componentDidMount() {
        UserModel.getInstance().addConnection(ConnectionType.TYPE_TOGGLE_PROFILE_VIEW, this.onToggleProfile)
    }

    componentWillUnmount() {
        UserModel.getInstance().removeConnection(ConnectionType.TYPE_TOGGLE_PROFILE_VIEW, this.onToggleProfile)
    }

    onToggleProfile() {
      if(this.state.show){
        this.setState({
          transition: 'hide',
          showEdit: false,
          showCardDetails: false,
          checkoutId:'',
          userImage: null
        });
        setTimeout(()=>{
          this.setState({
            noCover: true,
            show: !this.state.show
        });
        },450)
      }
      else{
        this.setState({
          noCover: false,
          show: !this.state.show
        });
        setTimeout(()=>{
          this.setState({transition: 'show'});
        },50)
      }
    }

    onSignOutPressed() {
        UserControl.getInstance().signOut()
    }

    selectImage(e){
      let file = e.target.files[0];
      if(file){
        let formData = new FormData();
        formData.append('profile_image', file, file.name);

        let url = URL.createObjectURL(file);
        let img = new Image();
        
        img.src = url;
        img.onload = () =>{
          if(
            img.width < 2000 && 
            img.height < 2000 && 
            (file.type.toLowerCase().includes('jpeg') || file.type.toLowerCase().includes('png'))
          ){
            this.setState({
              userImage: url,
              userImageType: file.type,
              userImageValid: true,
              userImageFile: formData
            });
          }
          else{
            this.setState({
              userImageValid: false
            })
          }
        }
      }
    }

    settingProfile = () => {
        this.setState({showEdit:!this.state.showEdit})
    };

    settingCardDetails = async () => {
        let getPaymentLink = UserControl.getInstance().getPaymentLink();
        await getPaymentLink.then(response => {
            this.setState({paymentLink:response.data});
        });
        let response = await UserControl.getInstance().getUserPaymentMethods();
        if(response){
            let paymentMethods = this.createPaymentMethods(response.payment_methods);
            this.setState({paymentMethods:paymentMethods});
            this.setState({showCardDetails:!this.state.showCardDetails});
        }
    };

    saveProfile = async () => {
      if(this.state.userImageValid){
        let response = await UserControl.getInstance().updateProfile({
          lastName: this.fldLastName.getCurrentValue(),
          email: this.fldEmail.getCurrentValue(),
          profileImage: (this.state.userImage) ? this.state.userImageFile : this.userData.profileImage,
          greenerChoices: this.userData.greenerChoices,
          pushNotifications: this.userData.pushNotifications,
          firstName: this.fldFirstName.getCurrentValue(),
        });
        
        if(response){
          this.setState({showEdit:!this.state.showEdit});
        }
      }
    };

    currentImage(){
      if(this.state.userImage){
        return this.state.userImage;
      }
      else{
        return this.userData.profileImage;
      }
    }

    createPaymentMethods(methods) {
        let paymentMethods = "";
        for (let j = 0; j < methods.length; j++) {
            paymentMethods += "<p style='color:white'>Card No : ************" + methods[j].last_four_digits + "</p><br/>"
        }
        return paymentMethods
    };

    renderCardForm(){
        return <div>
            <h1 className="Hey_Title">Link a card below.</h1>
            <iframe id="payment_link" align={'center'} scrolling={'yes'} width="1000" height="700"  src={this.state.paymentLink}></iframe>
        </div>
    };

    renderCardDetails(){
        if(this.state.showCardDetails){
            return(
                <React.Fragment>
                    <div className="MMenu_Hey">
                        <h3>Current linked payment methods</h3>
                        <br/>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.state.paymentMethods
                            }}>
                        </div>
                        <MModalButton title="Link a payment method">
                            {this.renderCardForm()}
                        </MModalButton>
                    </div>
                </React.Fragment>
            )
        }
    }

    renderEdit(){
      if(this.state.showEdit){
        return(
          <React.Fragment>
            <div className="userImageWrapp">
              <img className="userImage" src={this.currentImage()} alt="Profile image" />
              <input
                className="userImageSelect" 
                type="file" 
                onClick={(e)=>{e.target.value = ''}} 
                onChange={this.selectImage}/>
            </div>
            <p className="fz-13 mt-20 bold">Name</p>
            <InputText
                theme={this.props.theme}
                className="mt-10 h-40"
                setReference={(ref) => this.fldFirstName = ref}
                defaultValue={this.userData.firstName}
                id="username"
                placeholder="Name"
                type="text"
            />
            <p className="fz-13 bold mt-20">Last name</p>
            <InputText
                theme={this.props.theme}
                className="mt-10 h-40"
                setReference={(ref) => this.fldLastName = ref}
                defaultValue={this.userData.lastName}
                id="userLastName"
                placeholder="Last name"
                type="text"
            />
            <p className="fz-13 bold mt-20">Email</p>
            <InputText
                theme={this.props.theme}
                className="mt-10 h-40"
                setReference={(ref) => this.fldEmail = ref}
                defaultValue={this.userData.email}
                id="userEmail"
                placeholder="Email"
                type="text"
            />
            <div className="wrapper wrapper_flex-center mt-30">
              <Button
                theme={this.props.theme}
                text="Save"
                className="h-40"
                onClick={this.saveProfile}
              ></Button>
              <Button
                theme={this.props.theme}
                text="Cancel"
                className="h-40 ml-15"
                secondary
                onClick={this.settingProfile}
              ></Button>
            </div>
          </React.Fragment>
        )
      }
    }

    hideContainer(){
      if (this.state.transition === 'hide') {
          return 'userProfileHide';
      }
      else{
        return '';
      }
    }
    hideCover(){
      if (this.state.transition === 'hide') {
          return 'wrapper_cover-hide';
      }
      else{
        return '';
      }
    }
    noCover(){
      if(this.state.noCover){
        return {display: 'none'};
      }
      else{
        return {};
      }
    }

    render() {
  
        return (
          <React.Fragment>
            <div className={`${theme[this.props.theme]} ${this.hideContainer()} userProfileContainer`}>
              <div className='userAvatarContainer'>
                <div onClick={this.onToggleProfile} className="wrapper hide-xl">
                  <Picture user src={this.userData.profileImage} alt='Avatar'/>
                </div>
                <div className="wrapper show-xl">
                  <Picture user src={this.userData.profileImage} alt='Avatar'/>
                </div>
                <div className="wrapper wrapper_flex-column-center ml-15">
                  <p className="username max-w-100 break-word fz-15 bold">{this.userData.firstName} {this.userData.lastName}</p>
                  <p className="userphone fz-12 mt-3">{this.userData.mobile}</p>
                </div>
                <div className="wrapper wrapper_flex-center ai-end flex-1">
                  <Button
                    theme={this.props.theme}
                    textonly 
                    className="icon-credit-card fz-20 ml-15 p-10"
                    onClick={this.settingCardDetails}
                  ></Button>
                  <Button
                    theme={this.props.theme}
                    textonly 
                    onClick={this.settingProfile} 
                    className="icon-pencil fz-20 p-10"
                  ></Button>
                  <br/>
                </div>
              </div>
              
              <MMenu></MMenu>

              <div className='wrapper wrapper_flex-column-center mt-30'>
                {this.renderEdit()}
              </div>

                <div className='wrapper wrapper_flex-column-center mt-30'>
                    {this.renderCardDetails()}
                </div>

              <Button
                theme={this.props.theme}
                textonly
                onClick={this.onSignOutPressed}
                className="userLogout icon-logout fz-20 p-10 hide-xl"
              ></Button>

            </div>
            <div onClick={this.onToggleProfile} style={this.noCover()} className={`${this.hideCover()} wrapper wrapper_cover wrapper_cover-fixed hide-xl`}></div>
            
          </React.Fragment>
        )
    }
}

export default UserProfile;